<template>
  <div class="findBack">
    <loginHeader></loginHeader>
    <div class="login-body flex0">
      <div class="login-detail">
        <div class="login-detail-title tc">找回密码</div>
        <div class="notice_area">
          <div class="notice">
            <span class="red">*</span>
            请填写您的登录邮箱，验证通过后，请至您的邮箱中查看邮件并通过邮件找回您的密码
          </div>
        </div>
        <div class="login-detail-form">
          <el-form class="loginForm" :model="form" ref="Forms" :rules="rules">
            <el-form-item prop="email">
              <el-input v-model="form.email" placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
            <el-form-item class="tc">
              <el-button type="info" size="medium" :loading="loading_1" @click="backToLogin" >前往登录</el-button>
              <el-button type="info" size="medium" :loading="loading_2" @click="send">确认</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginHeader from "@/components/loginHeader.vue";
import { sendEmail } from "@/api/register.js";
import { dataState } from "@/api/all.js";
import { mapState } from 'vuex'
import { ApiToken } from '@/utils/ApiToken'

export default {
  components: {
    loginHeader,
  },
  name: "findBack",
  data() {
    return {
      errorMsg: "", //错误信息
      loading_1: false,
      loading_2: false,
      form: {
        email: "",
        baseUrl: "",
      },
      rules: {
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.form.baseUrl = ApiToken.shareUrl
  },
  mounted() {
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "FindBack",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    send() {
      this.$refs["Forms"].validate((valid) => {
        if (valid) {
          this.loading_2 = true;
          sendEmail(this.form).then((res) => {
            if (res.status == 1) {
              this.$toast.success({
                message: "发送成功！",
                duration: 1000,
              });
              this.loading_2 = false;
              setTimeout(() => {
                if (this.channel === 1) {
                  this.$router.push('/Home/Live')
                } else {
                  this.$router.push('/')
                }
              }, 1000);
            } else {
              this.loading_2 = false;
              this.errorMsg = null;
              this.$toast({
                message: res.message,
                duration: 2000,
              });
              this.$nextTick(() => {
                this.errorMsg = res.message;
              });
            }
          });
        } else {
          this.loading_2 = false;
          return false;
        }
      });
    },
    backToLogin() {
      this.loading_1 = true;
      if(this.$route.query.from) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl + '&from=' + this.$route.query.from)
      } else if (this.channel === 1) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl)
      } else {
        this.$router.push('/Login')
      }
    },
  },
  computed: {
    ...mapState(['clientWidth', 'channel'])
  }
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

#app .el-button {width: calc((100% - 20px) / 2)}
.red{color: #F60000;}

@media (min-width: 750px) {
  .findBack {min-height: 500px;height: 100vh;background: #f7f7f7;
    .login-body {width: 100%;height: calc(100% - 83px);
      .login-detail {width: 448px;height: 376px;box-shadow: 0 2px 4px 0 rgb(226, 224, 224), 0 0 6px 0 rgb(226, 224, 224);
        .login-detail-title {height: 78px;line-height: 78px;font-size: 24px;

          .login-detail-title-item {
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            line-height: 78px;
            text-align: center;
            border: 1px #f7f7f7 solid;
            cursor: pointer;
          }

          .login-detail-title-item-active {
            background: #fff;
            border-bottom: 1px #fff solid;
            color: #6b18a8;
          }
        }

        .notice_area {
          background: #fff;
          .notice {
            width: 280px;
            padding-top: 45px;
            margin: 0 auto;
          }
        }

        .login-detail-form {
          height: calc(100% - 161px);
          background: #fff;

          .loginForm {
            width: 280px;
            padding-top: 30px;
            margin: 0 auto;
          }
        }
      }
    }

    .login {
      .el-tabs__item {
        width: 50%;
        box-sizing: border-box;
      }

      .login-verification {
        padding-top: 30px;
        width: 280px;
        margin: 0 auto;
      }

    }
  }
}

@media (max-width: 750px) {
  * {font-size: vw(24);}
  .findBack {min-height: 100vh;background: #f7f7f7;
    .login-body {width: 100%;min-height: calc(100vh - 16vw)}
    .login-detail {width: vw(670);height: vw(590);margin: 0 auto;overflow: hidden;box-shadow: 0 2px 4px 0 rgb(226, 224, 224), 0 0 6px 0 rgb(226, 224, 224);}
    .login-detail-title {height: vw(80);line-height: vw(80);font-size: vw(29);}
    .login-detail-form {height: calc(100% - 10.6667vw);padding-top: vw(38);background: #fff;
		.loginForm {width: vw(558);margin: 0 auto;}
		}
		.notice {background: #fff;padding: vw(90) vw(56) 0 vw(56);}
  }
}
</style>
